/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/06/2021.
 */
import EventsStrategy from "./EventsStrategy";
import moment from "moment";
import { RRule, RRuleSet } from "rrule";
import { cleanRRule } from "../../helpers/calendar";
import { getLinksFromDescription } from "../../helpers/widgetItems";

/**
 * Converts string date from format YYYY-MM-DD to Date object because Safari doesn't support this format
 * @param date
 * @returns {Date}
 */
const stringDateToDate = (date) => {
  return date.replace(/-/g, "/");
};

/**
 *
 * @param value
 * @param defaultValue
 * @returns {*}
 */
const sanitizeValue = (value, defaultValue) => {
  return value && !Array.isArray(value) ? value : defaultValue;
};

/**
 *
 * @param description
 * @returns {*}
 */
const sanitizeDescription = (description) => {
  if (description && description.length) {
    return description.replace(/<img[^>]+\>{1}/, "");
  }
};

const RepeatUnitsMapping = {
  daily: "day",
  weekly: "week",
  monthly: "month",
  yearly: "year",
};
/**
 *
 * @param startDate
 * @param recurrenceDesc
 * @returns {string|null}
 */
const parseRecurrenceSeries = (startDate, event) => {
  // input_repeat_ends = never, on_date,
  // input_repeat_units = weekly, daily, monthly, repeat_custom
  // input_repeat_end_on_date = mm/dd/yyyy
  // input_repeat_ends_after = number
  // event_repeat_specific_dates = yyyy-mm-dd

  try {
    let description = "";

    if (event.input_repeat_units === "repeat_custom") {
      if (event.event_repeat_specific_dates) {
        const specificDates = event.event_repeat_specific_dates.split(",");
        const rruleSet = new RRuleSet();
        rruleSet.rrule(
          new RRule({
            dtstart: new Date(startDate * 1000),
          })
        );

        const start = new Date(startDate * 1000);
        const startTime = start.toLocaleTimeString();

        specificDates.map((i) => {
          const recurringDate = new Date(stringDateToDate(i) + ` ${startTime}`);

          if (recurringDate && recurringDate.getTime() !== NaN) {
            rruleSet.rdate(recurringDate);
          }
        });

        return [rruleSet.toString(), "on specific dates"];
      }
    } else {
      description = `every ${RepeatUnitsMapping[event.input_repeat_units]}`;

      if (event.input_repeat_ends_after) {
        description += ` for ${event.input_repeat_ends_after} times`;
      } else if (event.input_repeat_end_on_date) {
        description += ` until ${event.input_repeat_end_on_date}`;
      }

      const options = RRule.parseText(description);

      options.dtstart = new Date(startDate * 1000);

      const cleanRule = cleanRRule(options);

      if (cleanRule) {
        return [cleanRule.toString(), cleanRule.toText()];
      }
    }
  } catch (e) {
    console.error(e);
  }

  return [null, null];
};

class BreezeEventsStrategy extends EventsStrategy {
  processData(data) {
    return data.map((i) => {
      const data = i.data;
      const eventProfile = data.details;

      const utcOffset = moment(i.start_date * 1000).utcOffset();

      let recurringDescription = null;
      let recurrence_series = null;

      const startDate = moment(i.start_date * 1000)
        // .add(utcOffset, "m")
        .unix();

      if (sanitizeValue(eventProfile.input_event_repeats, null) === "on") {
        const [series, description] = parseRecurrenceSeries(
          startDate,
          eventProfile
        );

        recurrence_series = series;
        recurringDescription = description;
      }

      const description = sanitizeDescription(
        sanitizeValue(eventProfile.event_description, "")
      );

      const isAllDayEvent = Number.parseInt(eventProfile.input_all_day) === 1;

      const descriptionLinks = getLinksFromDescription(description);

      const organizer = {
        id: "",
        name:
          [
            sanitizeValue(eventProfile.account_settings_modal_first_name, ""),
            sanitizeValue(eventProfile.account_settings_modal_last_name, ""),
          ]
            .join(" ")
            .trim() || null,
        email: sanitizeValue(eventProfile.account_settings_modal_email, null),
      };

      const ctas = {
        linkFirst: descriptionLinks[0] || null,
        linkLast: descriptionLinks[1] || null,
        eventPage: null,
        form: null,
        organizer:
          organizer && organizer.email
            ? {
                url: `mailto:${organizer.email}`,
                label: organizer.name,
              }
            : null,
      };

      return {
        created: data.created,
        //created_by: eventProfile.account_settings_modal_first_name
        //  ? `${eventProfile.account_settings_modal_first_name} ${eventProfile.account_settings_modal_last_name}`
        //  : null,
        start_time: startDate,
        end_time: moment(i.end_date * 1000)
          // .add(utcOffset, "m")
          .unix(),
        allDay: isAllDayEvent,
        timezone: "UTC",
        recurrence_description: recurringDescription,
        recurrence_series,
        name: data.name,
        description,
        id: i.id,
        //modified: eventProfile.modified,
        //modified_by: eventProfile.modifier["$"],
        integration_event_id: i.platform_event_id,
        slug: i.short_slug,

        cover_image:
          i.attachments && i.attachments.length
            ? sanitizeValue(i.attachments[0].data.src, null)
            : null,

        display_location:
          data.locations && data.locations.length
            ? data.locations[0].name
            : null,
        location:
          data.locations && data.locations.length
            ? {
                name: data.locations[0].name,
                city: " ",
              }
            : null,

        organizer,

        accentColor: i.calendar ? `#${i.calendar.data.color}` : null,
        ctas,
        is_featured: i.is_featured,
      };
    });
  }
}

export default BreezeEventsStrategy;
