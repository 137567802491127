/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/07/2024.
 */

/**
 * TODO
 * This is a tempoprary solution when pulling CCB groups filters
 * coming from user defined fields. We are interested in demographic
 * fields only but the API will return all UDF fields.
 * Here we filter out all UDF fields except the ones with label "Demographic".
 */

const IntegrationFiltersMiddleware = (storeAPI) => (next) => (action) => {
  switch (action.type) {
    case "INTEGRATION_FILTERS_FETCH_FULFILLED":
    case "WIDGET_FILTERS_FETCH_FULFILLED":
      action.payload = action.payload.filter((i) => {
        if (i.kind !== "udf") {
          return true;
        }

        if (i.data?.label?.toLowerCase()?.includes("demographic")) {
          return true;
        }

        return false;
      });

      break;
  }

  next(action);
};

export default IntegrationFiltersMiddleware;
