/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/06/2021.
 */

import { CT, IT } from "../../../consts";

export const definitions = {
  title: "Forms",
  icon: "playlist_add_check",
  groups: [
    {
      label: "Behavior",
      options: [
        {
          title: "Show event form(s)",
          templates: [
            CT.cardList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.eventList,
            CT.cardSlider,
            CT.detailedList,
          ],
          integrations: [IT.ccb],
          name: "formShowForms",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4616186-design-events-forms",
            message: "Display/hide links to event forms",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show event form(s)",
          templates: [
            CT.cardList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.eventList,
            CT.cardSlider,
            CT.detailedList,
          ],
          integrations: [IT.pco],
          name: "formShowForms",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/5583239-design-forms-pco",
            message: "Display/hide links to event forms",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show form description",
          templates: [
            CT.cardList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.eventList,
            CT.cardSlider,
            CT.detailedList,
          ],
          integrations: [IT.ccb],
          name: "formShowDescription",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4616186-design-forms",
            message: "Display/hide form description",
          },
          show: [{ field: "formShowForms", value: 1, defaultValue: 0 }],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Form section title",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
          ],
          integrations: [IT.pco],
          name: "formSectionTitle",
          defaultValue: "Registration",
          tooltip: {
            url: "https://help.display.church/en/articles/5583239-design-forms-pco",
            message: "Give the form link a meaningful name",
          },
          show: [{ field: "formShowForms", value: 1, defaultValue: 0 }],
          control: {
            kind: "text",
            options: {
              placeholder: "Title of registration section",
            },
          },
        },
        {
          title: "Form link button label",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
          ],
          integrations: [IT.ccb],
          name: "formLinkLabel",
          defaultValue: "Open Form",
          tooltip: {
            url: "https://help.display.church/en/articles/4616186-design-forms",
            message: "Give the form link a meaningful name",
          },
          show: [{ field: "formShowForms", value: 1, defaultValue: 0 }],
          control: {
            kind: "text",
            options: {
              placeholder: "Form link button label",
            },
          },
        },
        {
          title: "Form link button label",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
          ],
          integrations: [IT.pco],
          name: "formLinkLabel",
          defaultValue: "Open Form",
          tooltip: {
            url: "https://help.display.church/en/articles/5583239-design-forms-pco",
            message: "Give the form link a meaningful name",
          },
          show: [{ field: "formShowForms", value: 1, defaultValue: 0 }],
          control: {
            kind: "text",
            options: {
              placeholder: "Form link button label",
            },
          },
        },
      ],
    },
  ],
};
