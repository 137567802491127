/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/02/2023.
 */

const initialState = {
  collection: [],
  pagination: null,

  fetch: false,
  fetchSuccess: false,
  fetchError: null,
};

export default function reducer(
  state = { ...initialState, collection: [] },
  action
) {
  switch (action.type) {
    case "QUEUE_JOBS_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: false,
      };

    case "QUEUE_JOBS_FETCH_FULFILLED":
      return {
        ...state,
        collection: action.payload.queuedJobs,
        pagination: action.payload.pagination,
        fetch: false,
        fetchSuccess: true,
      };

    case "QUEUE_JOBS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.payload,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        collection: [...state.collection],
      };

    case "QUEUE_JOBS_CLEAR":
      return {
        ...initialState,
      };

    case "USER_LOGOUT":
      return { ...initialState, collection: [] };

    default:
      return state;
  }

  return state;
}
