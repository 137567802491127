/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/07/2022
 */

/**
 *
 * @param String anchor
 */
export const getLinkFromAnchor = (anchor) => {
  const container = document.createElement("div");
  container.innerHTML = anchor.trim();

  const aTag = container.firstChild;

  if (!aTag) {
    return null;
  }
  return {
    url: aTag.href,
    label: aTag.innerHTML,
  };
};

const urlRegex =
  /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

/**
 * Linkify text. Finds all urls in text and wraps them in anchor tags
 * @param text
 * @returns {*}
 */
export const linkify = (text) => {
  // replace url with anchor tag in text only if the url is not already wrapped in an anchor tag

  return text.replaceAll(
    /(?:http([s]?):\/\/)?((\w+[.])+\w{2,}(\/\w*)*(\?[^\s]*)*)(?!.*>)/gi,
    // /<a href="http$1://$2">$2</a>/
    (m, p1, p2) =>
      `<a href="http${p1 ? "s" : ""}://${p2}">${p1 ? p1 + "://" : ""}${p2}</a>`
  );
};

/**
 * Get links from description
 * @param description
 * @returns {*[]|*[{url: *, label: *}]}
 */
export const getLinksFromDescription = (description) => {
  if (!description) {
    return [];
  }
  const descriptionAnchors = description.match(
    /(<a[\s]+([^>]+)>((?:.(?!\<\/a\>))*.)<\/a>)/g
  );

  const links =
    descriptionAnchors && descriptionAnchors.length
      ? [descriptionAnchors[0]]
      : [];

  if (descriptionAnchors && descriptionAnchors.length > 1) {
    links.push(descriptionAnchors.splice(-1)[0]);
  }

  const firstLink = links[0] ? getLinkFromAnchor(links[0]) : null;
  const lastLink = links[1] ? getLinkFromAnchor(links[1]) : null;

  return [firstLink, lastLink];
};

export const getImageFromImageTag = (tag) => {
  const container = document.createElement("div");
  container.innerHTML = tag.trim();

  const imgTag = container.firstChild;

  if (!imgTag) {
    return null;
  }

  return {
    url: imgTag.src,
    label: imgTag.title || imgTag.alt || "",
  };
};

/**
 *
 * @param description
 * @returns {*|*[]}
 */
export const getImagesFromDescription = (description) => {
  if (!description) {
    return [];
  }
  const descriptionImages = description.match(
    /<img\s+[^>]*src="([^"]*)"[^>]*>/g
  );
  if (!descriptionImages) {
    return [];
  }
  return descriptionImages.map((img) => {
    return getImageFromImageTag(img);
  });
};
