/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */
import { dceDebug } from "../../../helpers/debug";

const initialState = {
  widgetLastModified: 0,
  integrationLastSynced: 0,

  collection: {},
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "EVENTS_CACHE_RESET":
    case "CALENDAR_UPDATE":
      return {
        ...initialState,
        collection: {},
      };

    case "EVENTS_CACHE_MODIFIED_SET":
      if (
        action.widgetLastModified > state.widgetLastModified ||
        action.integrationLastSynced > state.integrationLastSynced
      ) {
        dceDebug(
          "EVENTS_CACHE_MODIFIED_SET",
          action.widgetLastModified,
          state.widgetLastModified,
          action.integrationLastSynced,
          state.integrationLastSynced
        );
        return {
          ...initialState,
          widgetLastModified: action.widgetLastModified,
          integrationLastSynced: action.integrationLastSynced,
          collection: {},
        };
      }

      dceDebug(
        "EVENTS_CACHE_MODIFIED_SET cache not modified",
        action.widgetLastModified,
        state.widgetLastModified,
        action.integrationLastSynced,
        state.integrationLastSynced
      );

      return state;

    case "EVENTS_FETCH_FULFILLED":
    case "FEATURED_EVENTS_FETCH_FULFILLED":
      if (!action.requestUri) {
        break;
      }
      const cache = {
        ...state.collection,
      };
      cache[action.requestUri] = action.payload.events;
      return {
        ...state,
        collection: cache,
      };
  }

  return state;
}
