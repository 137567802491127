import { CT, IT } from "../../../consts";

const showConditionsEvent = [
  {
    field: "eventDetailsShowShareOptions",
    value: 1,
    defaultValue: 1,
  },
];

const showConditionsCalendar = [
  {
    field: "calendarShowShare",
    value: 1,
    defaultValue: 0,
  },
];

const showConditionsCalendarOrEvent = [
  ...showConditionsEvent,
  {
    field: "calendarShowShare",
    value: 1,
    defaultValue: 0,
    or: true,
  },
];

showConditionsCalendarOrEvent[1].or = true;

export const definitions = {
  title: (
    <span>
      Sharing <span className={"badge badge-success"}>NEW</span>
    </span>
  ),
  icon: "share",
  groups: [
    {
      label: "Share calendar",
      options: [
        {
          title: "Show share button",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          applicable: "design",
          name: "calendarShowShare",
          defaultValue: 0,
          tooltip: {
            url: "http://help.display.church/en/articles/9203820-display-church-design-sharing-show-share-button-for-entire-calendar",
            message: "Toggle on or off the calendar share button",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Share button background",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.bubble,
          ],
          name: "calendarShareBtnBg",
          defaultValue: "#D3D3D3",
          show: showConditionsCalendar,
          style: [
            {
              selector: [".dc-calendar-share-button"],
              property: "background-color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/9203820-display-church-design-sharing-show-share-button-for-entire-calendar",
            message: "The color of the button itself.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Share button background",
          templates: [CT.detailedList],
          name: "calendarShareBtnBg",
          defaultValue: "#fff",
          show: showConditionsCalendar,
          style: [
            {
              selector: [".dc-detailed-list--calendar-share-button"],
              property: "background-color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/9203820-display-church-design-sharing-show-share-button-for-entire-calendar",
            message: "The color of the button itself.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Share button background on hover",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.bubble,
          ],
          name: "calendarShareBtnBgHover",
          defaultValue: "#d3d3d3",
          show: showConditionsCalendar,
          style: [
            {
              selector: [".dc-calendar-share-button:hover"],
              property: "background-color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/9203820-display-church-design-sharing-show-share-button-for-entire-calendar",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Share button background on hover",
          templates: [CT.detailedList],
          name: "calendarShareBtnBgHover",
          defaultValue: "#edf2f7",
          show: showConditionsCalendar,
          style: [
            {
              selector: [".dc-detailed-list--calendar-share-button:hover"],
              property: "background-color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/9203820-display-church-design-sharing-show-share-button-for-entire-calendar",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Share button label color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.bubble,
          ],
          name: "calendarShareBtnLabel",
          defaultValue: "#333",
          show: showConditionsCalendar,
          style: [
            {
              selector: [".dc-calendar-share-button"],
              property: "color",
            },
          ],
          tooltip: {
            url: "http://help.display.church/en/articles/9203820-display-church-design-sharing-show-share-button-for-entire-calendar",
            message: "The color of the name and icon.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Share button label color",
          templates: [CT.detailedList],
          name: "calendarShareBtnLabel",
          defaultValue: "#2d3748",
          show: showConditionsCalendar,
          style: [
            {
              selector: [".dc-detailed-list--calendar-share-button"],
              property: "color",
            },
          ],
          tooltip: {
            url: "http://help.display.church/en/articles/9203820-display-church-design-sharing-show-share-button-for-entire-calendar",
            message: "The color of the name and icon.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Share button label color on hover",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.bubble,
          ],
          name: "calendarShareBtnLabelHover",
          defaultValue: "#333",
          show: showConditionsCalendar,
          style: [
            {
              selector: [".dc-calendar-share-button:hover"],
              property: "color",
            },
          ],
          tooltip: {
            url: "http://help.display.church/en/articles/9203820-display-church-design-sharing-show-share-button-for-entire-calendar",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Share button label color on hover",
          templates: [CT.detailedList],
          name: "calendarShareBtnLabelHover",
          defaultValue: "#2d3748",
          show: showConditionsCalendar,
          style: [
            {
              selector: [".dc-detailed-list--calendar-share-button:hover"],
              property: "color",
            },
          ],
          tooltip: {
            url: "http://help.display.church/en/articles/9203820-display-church-design-sharing-show-share-button-for-entire-calendar",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Share event",
      options: [
        {
          title: "Show share options in event details",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "eventDetailsShowShareOptions",
          defaultValue: 1,
          show: [{}],
          tooltip: {
            url: "https://help.display.church/en/articles/4616046-design-events-show-share-options",
            message: "Display sharing options for individual events",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Share link",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "sharingItemColor",
          defaultValue: "#808080",
          show: showConditionsEvent,
          style: [
            {
              selector: [".event-row--details .share-event.share-event--link"],
              property: "color",
            },
          ],
          tooltip: {
            url: "http://help.display.church/en/articles/9203703-display-church-styling-your-individual-event-sharing-button",
            message: "Color of share link in description",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Share link hover",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "sharingItemHoveredColor",
          defaultValue: "#fff",
          show: showConditionsEvent,
          style: [
            {
              selector: [
                ".event-row--details .share-event.share-event--link:hover",
              ],
              property: "color",
            },
          ],
          tooltip: {
            url: "http://help.display.church/en/articles/9203703-display-church-styling-your-individual-event-sharing-button",
            message: "Color of share link on mouse over",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Share link background hover",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "sharingItemHoveredBgColor",
          defaultValue: "#9e9e9e",
          show: showConditionsEvent,
          style: [
            {
              selector: [
                ".event-row--details .share-event.share-event--link:hover",
              ],
              property: "background-color",
            },
          ],
          tooltip: {
            url: "http://help.display.church/en/articles/9203703-display-church-styling-your-individual-event-sharing-button",
            message: "Color of circle around share link on mouse over",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Popup design",
      options: [
        {
          title: "Background color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "sharePopupBgColor",
          defaultValue: "#fff",
          show: showConditionsCalendarOrEvent,
          style: {
            selector: ".dc-share-popup--card",
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Card title color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "sharePopupTitleColor",
          defaultValue: "#000",
          show: showConditionsCalendarOrEvent,
          style: {
            selector: [".dc-share-popup--card-section--header"],
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Source color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "sharePopupSourceColor",
          defaultValue: "#000",
          show: showConditionsCalendarOrEvent,
          style: {
            selector: ".dc-share-popup--card-section--subheader",
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Separator color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "sharePopupSeparatorColor",
          defaultValue: "#e0e0e0",
          show: showConditionsCalendarOrEvent,
          style: {
            selector: ".dc-share-popup--card-section",
            property: "border-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Close button color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "sharePopupCloseButtonColor",
          defaultValue: "#333",
          show: showConditionsCalendarOrEvent,
          style: {
            selector: [
              ".dc-share-popup--card--close::before",
              ".dc-share-popup--card--close::after",
            ],
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Close button color on hover",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "sharePopupCloseButtonColorOnHover",
          defaultValue: "#333",
          show: showConditionsCalendarOrEvent,
          style: {
            selector: [
              ".dc-share-popup--card--close:hover::before",
              ".dc-share-popup--card--close:hover::after",
            ],
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "QR Code",
      options: [
        {
          title: "Show QR code",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "popupQrCode",
          defaultValue: 1,
          show: showConditionsCalendarOrEvent,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "QR code background color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "qrCodeBgColor",
          defaultValue: "#fff",
          show: [
            ...showConditionsCalendarOrEvent,
            {
              field: "popupQrCode",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "QR code foreground color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "qrCodeFgColor",
          defaultValue: "#000",
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: [
            ...showConditionsCalendarOrEvent,
            {
              field: "popupQrCode",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
        {
          title: "QR code border color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "qrCodeBorderColor",
          defaultValue: "#e0e0e0",
          style: {
            selector: ".dc-share-popup--card-section--qr svg",
            property: "border-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: [
            ...showConditionsCalendarOrEvent,
            {
              field: "popupQrCode",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
      ],
    },
    {
      label: "Social buttons",
      options: [
        {
          title: "Show share on facebook",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
          ],
          name: "shareFacebookShow",
          defaultValue: 1,
          show: showConditionsCalendarOrEvent,
          control: {
            kind: "checkbox",
            options: {},
          },
        },

        {
          title: "Show share on X",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
          ],
          name: "shareXButtonShow",
          defaultValue: 1,
          show: showConditionsCalendarOrEvent,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Shareable URL",
      options: [
        {
          title: "Show share link",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "popupShareLink",
          defaultValue: 1,
          show: showConditionsCalendarOrEvent,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Link background color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "popupLinkBgColor",
          defaultValue: "#fff",
          style: {
            selector: ".dc-share-popup--link-input",
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: [
            ...showConditionsCalendarOrEvent,
            {
              field: "popupShareLink",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
        {
          title: "Link color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "popupLinkColor",
          defaultValue: "#000",
          style: {
            selector: ".dc-share-popup--link-input_value",
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: [
            ...showConditionsCalendarOrEvent,
            {
              field: "popupShareLink",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
        {
          title: "Link border color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "popupLinkBorderColor",
          defaultValue: "#e0e0e0",
          style: {
            selector: ".dc-share-popup--link-input",
            property: "border-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: [
            ...showConditionsCalendarOrEvent,
            {
              field: "popupShareLink",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
        {
          title: "Copy button color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "popupCopyButtonColor",
          defaultValue: "#2646CB",
          style: {
            selector: ".dc-share-popup--link-input_button",
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: [
            ...showConditionsCalendarOrEvent,
            {
              field: "popupShareLink",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
        {
          title: "Copy button color on hover",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "popupCopyButtonColorOnHover",
          defaultValue: "#1a2f8c",
          style: {
            selector: ".dc-share-popup--link-input_button:hover",
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: [
            ...showConditionsCalendarOrEvent,
            {
              field: "popupShareLink",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
      ],
    },
  ],
};
