/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/03/2022.
 */

import { dceDebug } from "./debug";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import {
  getEmbeddableScriptDom,
  getEmbeddableSource,
  onDocumentLoad,
  onLocationChange,
  postMessageToWixIframes,
} from "./launcher";
import IframeResizer from "iframe-resizer-react";
import "iframe-resizer/js/iframeResizer";
import "iframe-resizer/js/iframeResizer.contentWindow";
import appConfig from "../appConfig";
import { persistor } from "../apps/admin/store";
import { PersistGate } from "redux-persist/integration/react";
/**
 * Fow wix only. When embedding HTML into wix, it creates an iframe with an embeddable code.
 * What needs to happen is to find all iframes that contain our widget and overwrite iframe innerHTML
 * into regular div that is a container for the widget app.
 * The embeddable code will send a message with its id that can used to locate our widget. Because there may be more
 * widgets within one wix page, it needs to iterate through all iframes.
 * Every embeddable sends its own message so on message, get widged ID, iterate through all iframes, locate it,
 * replace it to div.
 * @param kind
 * @param selector
 * @param launchCallback
 */
export const initializeCrossIframeMessaging = (
  kind,
  selector,
  launchCallback
) => {
  window.__DCE[kind].wixOverrideInitializedWidgets = 0;

  window.addEventListener("message", (event) => {
    // calendarID for backwards compatibility
    const widgetId = event.data.widgetId || event.data.calendarId;

    if (!widgetId) {
      return;
    }

    const wixFrames = document.querySelectorAll("wix-iframe");
    let iFrame = null;
    for (let i = 0; i < wixFrames.length; i++) {
      if (wixFrames[i].data.initId == event.data.initId) {
        iFrame = wixFrames[i];
      }
    }

    iFrame.innerHTML = `<div class="${selector}" id="${widgetId}"/>`;

    window.__DCE[kind].wixOverrideInitializedWidgets++;

    if (window.__DCE[kind].wixOverrideInitializedWidgets == wixFrames.length) {
      launchCallback();
      window.__DCE[kind].wixOverrideInitializedWidgets = 0;
    }
  });
};

/**
 * This will post message to all iframes. If our iframe will receive it, it will ping us back
 * with a message with it's id. If that happens, the message processing initialized in `initializeCrossIframeMessaging`
 * will execute.
 */
const launchWixWidget = () => {
  postMessageToWixIframes((iframe, i) => {
    dceDebug("postMessageToWixIframes", iframe, i);
    iframe.closest("wix-iframe").data = { initId: i };
    iframe.closest("wix-iframe").closest("div").style.height = "auto";
    iframe.contentWindow.postMessage({ isDce: i }, "*");
  });
};

/**
 *
 * @param kind
 * @param uuid
 * @param iframed
 * @param backendAddress
 * @param slug
 * @param singleEventSlug
 * @param reactConfig
 * @returns {boolean}
 */
const runWidget = (
  kind,
  uuid,
  iframed,
  backendAddress,
  slug,
  singleEventSlug,
  reactConfig
) => {
  const app = document.getElementById(uuid);

  if (iframed) {
    const dceWidgetId = `dce-widget-${uuid}`;
    const kindLetter = kind.charAt(0);

    const iframeAddress = backendAddress.replace("cdn.", "");

    ReactDOM.render(
      <IframeResizer
        src={`${iframeAddress}/${kindLetter}/${slug}`}
        style={{ width: "1px", minWidth: "100%" }}
        frameBorder="0"
        className="dce-widget-iframe"
        id={dceWidgetId}
        checkOrigin={false}
      />,
      app
    );

    window.__DCE[kind].init = false;
  } else {
    dceDebug("####", `.dce-${kind}`, app.querySelector(`.dce-${kind}`));

    if (
      window.__DCE[kind].initializedWidgets.indexOf(uuid) >= 0 ||
      (app.children && app.children.length && app.querySelector(`.dce-${kind}`))
    ) {
      dceDebug("Widget already initialized");
      window.__DCE[kind].init = false;
      window.__DCE[kind].initializedWidgets.push(uuid);
      return false;
    }

    window.__DCE[kind].initializedWidgets.push(uuid);

    //console.log(store);

    if (reactConfig.usePersistor) {
      const { store, persistor } = reactConfig.getStore(uuid);
      dceDebug(store);

      ReactDOM.render(
        <Provider store={store}>
          <ConnectedRouter history={reactConfig.history}>
            <PersistGate loading={null} persistor={persistor}>
              <reactConfig.RouterClass
                widgetUUID={uuid}
                singleItemSlug={singleEventSlug}
                backendAddress={backendAddress}
              />
            </PersistGate>
          </ConnectedRouter>
        </Provider>,
        app
      );
    } else {
      const { store } = reactConfig.configureStore(uuid);
      dceDebug(store);

      ReactDOM.render(
        <Provider store={store}>
          <ConnectedRouter history={reactConfig.history}>
            <reactConfig.RouterClass
              widgetUUID={uuid}
              singleItemSlug={singleEventSlug}
              backendAddress={backendAddress}
            />
          </ConnectedRouter>
        </Provider>,
        app
      );
    }
  }
};

const launchWidget = (kind, widgetSelector, reactConfig) => {
  dceDebug("launchWidget()", kind, widgetSelector);
  const widgetObjects = document.querySelectorAll(widgetSelector);
  if (!widgetObjects.length) {
    console.warn(
      `${appConfig.displayName}: No ${kind} widget objects present on this page`
    );
  } else {
    for (let i = 0; i < widgetObjects.length; i++) {
      const widgetAttributes = widgetObjects[i].attributes;
      const isIframe =
        widgetAttributes.iframe && widgetAttributes.iframe.value === "true";

      const slug = isIframe ? widgetAttributes.slug.value : null;

      let singleItemSlug = widgetAttributes.singleitem
        ? widgetAttributes.singleitem.value
        : null;

      if (widgetAttributes.singleeventslug) {
        // fallback to v2
        singleItemSlug = widgetAttributes.singleeventslug.value;
      }

      dceDebug("DCE launchWidget()", widgetObjects[i].id);

      const interval = setInterval(
        () => {
          if (window.__DCE[kind].init) {
            console.log(
              "Other widget is loading [" + widgetObjects[i].id + "]"
            );
            return;
          }

          window.__DCE[kind].init = true;

          clearInterval(interval);
          const backendAddress = getEmbeddableSource();

          try {
            runWidget(
              kind,
              widgetObjects[i].id,
              isIframe,
              backendAddress,
              slug,
              singleItemSlug,
              reactConfig
            );
          } catch (e) {
            console.log("Loading widget" + widgetObjects[i].id + " failed");
            console.error(e);
          }
        },

        300
      );
    }
  }
};

/**
 *
 * @param kind
 * @param widgetSelector
 * @param reactConfig
 */
export const runWidgetInitialization = (kind, widgetSelector, reactConfig) => {
  const scriptTag = getEmbeddableScriptDom();

  if (!scriptTag || scriptTag.getAttribute("wix") == undefined) {
    onDocumentLoad(kind, () => {
      launchWidget(kind, widgetSelector, reactConfig);
    });

    onLocationChange(() => {
      dceDebug("onLocationChange");
      // location has changed, most likely, calendars needs to be reloaded
      // check if calendars are present on the website, perhaps location change did not made DOM to reload
      const verifiedCalendars = [];

      window.__DCE[kind].initializedWidgets.forEach((widgetId) => {
        const widgetDom = document.querySelector(`.dce_${kind}__${widgetId}`);

        if (widgetDom) {
          // calendar initialized, dom lost due to page reload
          verifiedCalendars.push(widgetId);
        }
      });

      window.__DCE[kind].initializedWidgets = [...verifiedCalendars];
      dceDebug(
        "onLocationChange, following widgets remain initialized:",
        verifiedCalendars
      );
      launchWidget(kind, widgetSelector, reactConfig);
    });
  } else {
    // Handle Wix

    initializeCrossIframeMessaging(kind, `dce-${kind}`, () =>
      launchWidget(kind, widgetSelector, reactConfig)
    );

    const wixLaunchCallback = () => {
      //launchWidget(kind, widgetSelector, reactConfig);
      launchWixWidget();
    };
    onDocumentLoad(kind, wixLaunchCallback);

    onLocationChange(() => {
      console.info(`${appConfig.shortDisplayName} location has changed...`);
      window.__DCE[kind].initializedWidgets = [];
      setTimeout(wixLaunchCallback, 1500);
    });
  }
};
